import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Header extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/toto">Toto</NavLink>
        <NavLink to="/twentyOnePilots">Twenty One Pilots</NavLink>
      </React.Fragment>
    );
  }
}

export default Header;
