import React, { Component } from "react";
import ReactHowler from "react-howler";
import raf from "raf"; // requestAnimationFrame polyfill
import { Button } from "reactstrap";
import { Spinner } from "reactstrap";
import { Progress } from "reactstrap";
import Select from "react-select";

class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      initialized: false,
      playing: false,
      playDisabled: true,
      stopDisabled: true,
      pauseDisabled: true,
      loaded: false,
      volume: 0.2,
      mute: false,
      loop: false,
      toto: []
    };
    this.initialize = this.initialize.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);
    this.renderSeekPos = this.renderSeekPos.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
    this.handleLoopToggle = this.handleLoopToggle.bind(this);
  }
  componentWillUnmount() {
    this.clearRAF();
  }
  initialize() {
    fetch("/songs.json")
      .then(res => res.json())
      .then(toto => {
        this.setState({
          initialized: true,
          toto: toto.toto
        });
      });
  }

  handleOnLoad() {
    this.setState({
      loaded: true,
      duration: this.player.duration(),
      playDisabled: false
    });
  }

  handleOnPlay() {
    this.setState({
      playing: true
    });
    this.renderSeekPos();
  }

  handlePlay() {
    this.setState({
      playing: true,
      playDisabled: true,
      pauseDisabled: false,
      stopDisabled: false,
      onEnd: true
    });
    this.clearRAF();
  }

  handleOnEnd() {
    // This function is called when the song stop playing
    this.setState({
      playing: false
    });
    this.clearRAF();
    // calling the stop function
    this.handleStop();
  }

  handlePause() {
    this.setState({
      playing: false,
      playDisabled: false,
      pauseDisabled: true
    });
  }

  handleMuteToggle() {
    this.setState({
      mute: !this.state.mute
    });
  }

  handleLoopToggle() {
    this.setState({
      loop: !this.state.loop
    });
  }

  handleStop() {
    this.player.stop();
    this.setState({
      playing: false,
      playDisabled: false,
      stopDisabled: true,
      pauseDisabled: true
    });
    this.renderSeekPos();
  }

  renderSeekPos() {
    this.setState({
      seek: this.player.seek()
    });
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  clearRAF() {
    raf.cancel(this._raf);
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  render() {
    if (this.state.initialized === true) {
      const { selectedOption } = this.state;
      const toto = this.state.toto;

      return (
        <div>
          {!this.state.seek ? (
            <div>
              <h3>Choose from the list below</h3>
              <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={toto}
                disabled={this.state.playDisabled}
              />
            </div>
          ) : (
            <div className="alert alert-light">
              {"Now playing: " + selectedOption.title}
            </div>
          )}
          {selectedOption ? (
            <div>
              <ReactHowler
                src={selectedOption.url}
                playing={this.state.playing}
                onLoad={this.handleOnLoad}
                onPlay={this.handleOnPlay}
                onEnd={this.handleOnEnd}
                ref={ref => (this.player = ref)}
                volume={this.state.volume}
                loop={this.state.loop}
                mute={this.state.mute}
              />
              <div className="btn-group mr-2">
                <Button
                  onClick={this.handlePlay}
                  disabled={this.state.playDisabled}
                >
                  Play
                </Button>
                <Button
                  onClick={this.handlePause}
                  disabled={this.state.pauseDisabled}
                >
                  Pause
                </Button>
                <Button
                  onClick={this.handleStop}
                  disabled={this.state.stopDisabled}
                >
                  Stop
                </Button>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.loop}
                  onChange={this.handleLoopToggle}
                />
                <label className="form-check-label">Loop:</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.mute}
                  onChange={this.handleMuteToggle}
                />
                <label className="form-check-label">Mute:</label>
              </div>

              <div>
                {"Status: "}
                {this.state.seek !== undefined
                  ? this.state.seek.toFixed(2)
                  : "0.00"}
                {" / "}
                {this.state.duration ? (
                  this.state.duration.toFixed(2)
                ) : (
                  <Spinner color="warning" size="sm" type="grow" />
                )}
              </div>
              <div className="col-12">
                {this.state.duration === undefined ? (
                  <Progress />
                ) : (
                  <Progress
                    value={(this.state.seek / this.state.duration) * 100}
                  />
                )}
              </div>

              <div className="volume">
                <label>
                  Volume:
                  <span className="slider-container">
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      value={this.state.volume}
                      onChange={e =>
                        this.setState({ volume: parseFloat(e.target.value) })
                      }
                      style={{ verticalAlign: "bottom" }}
                    />
                  </span>
                  {this.state.volume.toFixed(2) * 10}
                </label>
              </div>
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          <Button
            color="primary"
            // onClick={e => this.setState({ initialized: true })}
            onClick={this.initialize}
          >
            Initialise TOTO Player
          </Button>
        </div>
      );
    }
  }
}

export default Loader;
