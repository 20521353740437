import React, { Component } from "react";
import TwentyOnePilotsPlayer from "../../players/TwentyOnePilotsPlayer";

class TwentyOnePilots extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>21</h1>
        <TwentyOnePilotsPlayer />
      </React.Fragment>
    );
  }
}

export default TwentyOnePilots;
