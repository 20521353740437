import React, { Component } from "react";
import TotoPlayer from "../../players/TotoPlayer";

class Toto extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>Toto</h1>
        <TotoPlayer />
      </React.Fragment>
    );
  }
}

export default Toto;
