import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Toto from "./components/toto/Toto";
import Error from "./components/error/Error";
import TwentyOnePilots from "./components/tewntyOnePilots/TwentyOnePilots";

class App extends Component {
  render() {
    return (
      <div className="container-fluid">
        <BrowserRouter>
          <div className="border-temp">
            <Header />
          </div>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/toto" component={Toto} />
            <Route path="/twentyOnePilots" component={TwentyOnePilots} />
            <Route component={Error} />
          </Switch>
          <div className="border-temp">
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
