import React, { Component } from "react";

class Home extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="border-temp">
          <h1>Home comp</h1>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
