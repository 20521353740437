import React, { Component } from "react";

class Error extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>Erro page</h1>
      </React.Fragment>
    );
  }
}

export default Error;
